<template>
  <ul class="effects">
    <Effect 
      v-for="effect in effects" 
      :id="effect.id" 
      :title="effect.title" 
      :key="effect.id"
      class="effects--item"
    />
  </ul>
</template>

<script>
import Effect from './Effect'
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Effects',
  components: { Effect },
  methods: mapActions(['setEffects']),
  computed: mapState(['effects']),
  async created() {
    if (!this.effects.length) {
      await this.setEffects();
    }
  }
}
</script>

<style scoped lang="scss">
.effects {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;

  font-size: 14px;


  &--item {
    // margin: 10px;
    
    &:hover {
      cursor: pointer;
      color: var(--main-red);
    }

    @media (max-width: 840px) {
      margin: 0 10px 5px 10px;
    }

  }
}


@media (max-width: 640px) {
  .effects {
    &--item {
      margin: 0 10px 5px 0;

      &:hover {
        cursor: default;
        color: #fff;
      }
    }
  }
}
</style>