<template>
  <div class="preloader">
    <span class="preloader--text">Загрузка</span>
    <div class="preloader--bar"></div>
  </div>
</template>

<script>
export default {
  name: 'Preloader'
}
</script>

<style lang="scss" scoped>

.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  height: 40px;

  &--text {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    color: #fff;
  }

  &--bar {
    border-radius: 14px;
    background: linear-gradient(to right, 
    #FF0000, #DCE31C, #41BE5E, #8800FF, #BC00FF,
    #FF0000, #DCE31C, #41BE5E, #8800FF, #BC00FF,);
    height: 6px;
    animation: 1.5s ease-in infinite both move-bar;
    width: 100%;
    background-size: 226%;
    background-position: left;

    &-right {
      background-position: right;
      display: none;
    }
  }

  @keyframes move-bar {
    0% {background-position: right;}
    100% {background-position: left;}
  }
}

</style>