<template>
  <li @click="handleFilter" :class="{clicked: clicked}">
    {{ title }}
  </li>
</template>

<script>
import { mapActions, mapState } from 'vuex';


export default {
  name: 'Effect',
  data() {
    return {
      clicked: false,
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {...mapState(['filters', 'filteredCatalogue'])},
  methods: {
    ...mapActions(['setFilteredCatalogue', 'handleFilters']),
    handleFilter() {
      const payload = { clicked: this.clicked, effectId: this.id}
      this.handleFilters(payload)
      this.setFilteredCatalogue(payload)
      this.clicked = !this.clicked;
    }
  }
}
</script>

<style>
.clicked {
  color: var(--main-red) !important;
}
</style>