<template>
  <li class="card">
    <div class="img">
      <router-link :to="{ name: 'product', params: { id: card.id, slug: card.slug }}">
        <p v-if="card.sticker !== 8" class="card--sticker">
          {{ stickerName }}
        </p>
        <div class="thumb-wrapper">
          <img 
            v-if="card.thumb"
            :src="card.thumb"
            :alt="card.title"
            class="bg"
          />
        </div>
      </router-link>

      <!-- <div class="links">
        <button class="btn" @click="handleAdding">
          <svg xmlns="http://www.w3.org/2000/svg" width="32.166" height="32.166" viewBox="0 0 32.166 32.166">
            <path d="M0,0H32.166V32.166H0Z" fill="none"/>
            <path class="icon" d="M9.042,23.444a2.681,2.681,0,1,0,2.681,2.681A2.677,2.677,0,0,0,9.042,23.444ZM1,3.34a1.344,1.344,0,0,0,1.34,1.34h1.34L8.505,14.853,6.7,18.123A2.685,2.685,0,0,0,9.042,22.1H23.784a1.34,1.34,0,0,0,0-2.681H9.042l1.474-2.681H20.5a2.668,2.668,0,0,0,2.345-1.38l4.8-8.7a1.335,1.335,0,0,0-1.166-1.984H6.642l-.9-1.917A1.33,1.33,0,0,0,4.538,2H2.34A1.344,1.344,0,0,0,1,3.34Zm21.444,20.1a2.681,2.681,0,1,0,2.681,2.681A2.677,2.677,0,0,0,22.444,23.444Z" transform="translate(0.34 0.681)"/>
          </svg>
        </button>
      </div> -->
    </div>

    <h4 class="card--title light"> {{ card.title }} </h4>
    <p class="card--price medium">
      <span class="indicator" :class="{ 'indicator_available': card.available }"></span>
      {{ card.price }} Р
    </p>
  </li>
</template>

<script>
import { mapState } from 'vuex'


export default {
  name: 'Card',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['stickers']),
    stickerName() {
      return this.stickers?.get(this.card.sticker)?.title || '';
    },
  }
  // methods: {
  //   ...mapActions(['handleCartState']),
  //   handleAdding() {
  //     this.handleCartState({
  //       product: { 
  //         id: this.card.id, thumb: this.card.thumb,
  //         price: this.card.price, currentPacking: this.card.weight,
  //         title: this.card.title, quantity: 1
  //       },
  //       newQuantity: null,
  //     })
  //   }
  // },
}
</script>

<style scoped lang='scss'>
.indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--main-red);

  &_available {
    background-color: #009F0B;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 19px;
  font-family: 'Montserrat', sans-serif;

  &--price {
    margin-top: 6px;
    font-size: 0.94rem;
    letter-spacing: 0.1rem;
  }

  &--title {
    margin-top: 20px;
    text-align: center;
  }

  &--sticker {
    position: absolute;
    top: 10px;
    right: 12px;
    height: 24px;
    padding: 3px 19px;
    background-color: rgba(0, 0, 0, .50);
    backdrop-filter: blur(30px);
    border-radius: 11px;
    font-size: 14px;
    z-index: 1;
  }
}

.img {
  position: relative;
  // cursor: pointer;
}

.thumb-wrapper {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  height: 100%;

  & .bg {
    width: 100%;
    height: 100%;
    transition: transform 3.3s ease;
  }

  &:hover .bg {
    transform: scale(1.3);
  }
  
}


// .links {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;

//   transition: .5s ease;
//   display: none;
//   position: absolute;
//   bottom: 15px;
//   right: 15px;
//   // transform: translate(-50%, -50%);
//   // -ms-transform: translate(-50%, -50%);

//   &:hover .btn{
//     cursor: pointer;
//   }
// }

// .img:hover .bg {
//   filter: blur(4px);
// }

// .img:hover .links {
//   display: block;
// }

// .btn {
//   background-color: var(--bg);
//   border-radius: 10px;
//   padding: 10px 12px;

//   & .icon {
//     fill: #fff;
//   }
  

//   &:hover .icon {
//     fill: var(--main-red);
//   }
// }

</style>
