<template>
  <div class="product-wrapper">
    <!-- <section class="notification">
      <img class="notification--img" src="@/assets/Catalogue/tree.png" alt="New Year Tree">
      <p class="notification--text">
      </p>
    </section> -->
    <section class="effects_wrapper">
      <span class="effects_wrapper--text">
        Выберите эффект:
      </span>
      <Effects />
    </section>
    <div v-if="!catalogue">There have been some trouble</div>
    <transition-group class="cards" v-if="!loading" name="card" tag="ul">
      <Card 
        v-for="card in filteredCatalogue"
        :key="card.id"
        :card="card"
        class="card-item"
      />
    </transition-group>
      <div v-else class="catalogue-loading">
        <Preloader />
      </div>
  </div>
</template>

<script>
import Card from './Card'
import Effects from './Effects'
import Preloader from '@/components/layout/Preloader'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'Cards',
  components: {
    Card,
    Effects,
    Preloader
  },
  methods: mapActions(['getCatalogue', 'nullifyFilters', 'getStickers']),
  computed: mapState([
    'loading', 'catalogue', 'filteredCatalogue', 'filters', 'stickers'
  ]),
  async created() {
    if (!this.stickers) {
      await this.getStickers();
    }
    await this.getCatalogue();

    if (this.filters.length) {
      this.nullifyFilters();
    }
  },
}
</script>

<style scoped lang='scss'>

// .notification {
//   grid-area: notification;
//   display: flex;
//   align-items: center;
//   border: 2px solid #D4D4D4;
//   border-radius: 33px;
//   font-family: 'Montserrat', 'sans-serif';
//   font-size: 12px;
//   font-weight: 400;
//   padding: 0px 22px;
//   letter-spacing: 0.08rem;
//   text-align: center;
//   line-height: 1rem;

//   &--img {
//     height: 104px;
//   }

// }

.effects_wrapper {
  font-family: 'Montserrat', sans-serif;
  grid-area: effects;
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
  letter-spacing: var(--letter-spacing-main);


  &--text {
    margin-right: 20px;
    font-weight: 300;

    @media (max-width: 640px) {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.cards {
  grid-area: cards;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 45px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 470px) {
    grid-template-columns: 1fr;
  }
}

.product-wrapper {
  display: grid;
  grid-template-areas: 
                    '. catalogue-loading catalogue-loading catalogue-loading .'
                    '. effects effects effects .'
                    '. cards cards cards .';
  grid-template-columns: 5% 12% 1fr 12% 5%;
  grid-template-rows: auto auto auto;
  row-gap: 42px;
}

.catalogue-loading {
  grid-area: catalogue-loading;
}

.card-item {
  transition: all 1s;
}
.card-enter, .card-leave-to {
  opacity: 0;
  transform: translateY(30px) scale(.25);
}
.card-leave-active {
  position: absolute;
}
</style>